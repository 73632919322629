import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import submitStandardForm from '/src/functions/forms/submitStandardForm'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))
const TextAreaField = loadable(() => import('/src/components/Form/TextAreaField'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const validationSchema = Yup.object({
    name: Yup.string().required('Full name is required'),
    phone: Yup.string().max(15, 'Cannot be longer than 15 digits').required('Phone number is required'),
    email: Yup.string().email('Email address is not valid').required('Email is required'),
    complaint: Yup.string().required('This field is required'),
    resolution: Yup.string()
})

const ComplaintsForm = ({ disclaimer }) => {
    const [isSubmitted, setSubmitted] = useState(false)

    return (
        <Formik
            initialValues={{
                name: '',
                phone: '',
                email: '',
                complaint: '',
                resolution: ''
            }}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    name: values.name,
                    phone: values.phone,
                    email: values.email,
                    complaint: values.complaint,
                    resolution: values.resolution
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'complaints-form',
                    firebaseData: firebaseData,
                    confirmationEmail: process.env.GATSBY_COMPLAINTS_EMAIL_ADDRESS,
                    emailTemplate: 'complaints-form-template'
                }

                setSubmitted(true)

                await submitStandardForm(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <RequiredText />

                    <FormRow>
                        <TextField
                            label={'Full name'}
                            name={'name'}
                            placeholder={'e.g. Emma Jones'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextField
                            label={'Phone number'}
                            name={'phone'}
                            format={'number'}
                            placeholder={'e.g. 07700900796'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextField
                            label={'Email address'}
                            name={'email'}
                            type={'email'}
                            placeholder={'e.g. name@email.com'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextAreaField
                            label={'Please state your complaint'}
                            name={'complaint'}
                            placeholder={'Type your concern here...'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextAreaField
                            label={'Suggested resolution'}
                            name={'resolution'}
                            placeholder={'How would you ideally like us to solve the complaint?'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </FormRow>

                    {disclaimer && <Alert content={disclaimer} />}

                    <CtaContainer align={'right'}>
                        <SubmitButton
                            initialText={'Send enquiry'}
                            sentText={'Enquiry sent!'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            disabled={!(isValid && dirty)}
                        />
                    </CtaContainer>
                </Form>
            )}
        </Formik>
    )
}

ComplaintsForm.propTypes = {
    /**
     * Disclaimer
     */
    disclaimer: PropTypes.string
}

ComplaintsForm.defaultProps = {
    disclaimer: '<p>This is the disclaimer message</p>'
}

export default ComplaintsForm
